import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';

import { TalesList } from '../components/TalesList';
import { Layout } from '../components/Layout';
import { BigSearch } from '../components/BigSearch';
import { ImagesTalePreviewData, TextTalePreviewData } from '../types';
import { getFieldsForTalePreview } from '../helpers/getFieldsForTalePreview';

interface DataProps {
    allTextTales: {
        nodes: TextTalePreviewData[];
    },
    allImagesTales: {
        nodes: ImagesTalePreviewData[];
    }
}


const MainPage: FC<PageProps<DataProps>> = ({ data, location }) => {
    const { allTextTales, allImagesTales } = data;

    const textTales = allTextTales.nodes.map(getFieldsForTalePreview('text'));
    const imagesTales = allImagesTales.nodes.map(getFieldsForTalePreview('images'));

    return (
        <>
            <Layout seoTitle={'Главная страница'} location={location}>
                <TalesList title="Популярные сказки" list={[...textTales, ...imagesTales]} />
            </Layout>
        </>
    );
};

export default MainPage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allTextTales(filter: {visits: {gte: 1}}) {
            nodes {
                _id
                slug
                name
                description
                timeToRead
                author_id
            }
        }
        allImagesTales(limit: 10) {
            nodes {
                _id
                author_id
                name
                slug
                visits
                images_amount
            }
        }
    }
`;
